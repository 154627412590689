import React, { useState } from "react";
import { Link } from "react-router-dom";
import Popup from "./Popup";
import StoreHours from "./StoreHours";
// import GIFT_CARD_IMG from "../images/send_gift.svg";
const Cookies = require("js-cookie");

const POP_UP_ID_NUMBER = "POP_UP_31"; //update this to show a new popup
const POP_UP_TITLE = "Announcements";
const POP_UP_DAYS_UNTIL_SHOW_AGAIN = 1;
export const GLOBAL_MESSAGE = <Link to="/contact">New Hours</Link>;
export default function GlobalPopup() {
  let defaultShowPopUp = true; // update this to stop showing popup

  const hasSeenPopUp = Cookies.get(POP_UP_ID_NUMBER) ? true : false;
  if (hasSeenPopUp) {
    defaultShowPopUp = false;
  }

  //if this particular popup cookie does not exist, show the popup by default
  const [show, setShow] = useState(defaultShowPopUp);

  const handleClose = () => {
    Cookies.set(POP_UP_ID_NUMBER, true, { expires: POP_UP_DAYS_UNTIL_SHOW_AGAIN });
    setShow(false);
  };
  // const handleShow = () => setShow(true);

  const POP_UP_MESSAGE = (
    <>
      {/* <div className="row mx-2 rounded bg-secondary d-flex py-2">
        <div className="col-md-7 text-center">
          <h2 className="text-danger text-center h3">
            Gift Card Promotion!{" "}
          </h2>
          <p>Buy $100 in gift cards (any denomination) and you will receive a $20
            gift certificate for free</p>
          <p><em>Offer expires December 23rd</em></p>
        </div>
        <div className="col-4 m-auto p-0">
        <img src={GIFT_CARD_IMG} className="mx-auto w-100 mb-2" /><br/>
          <Link to="/order">
            <button onClick={handleClose} className="btn btn-danger btn-lg">
              Order Online
            </button>
          </Link>
        </div>
      </div> */}
      <h1>Hours</h1>
      <StoreHours></StoreHours>

      {/* <p>
        Gift cards can be purchased in person or <Link to="/order" onClick={handleClose}>online</Link>
      </p> */}
      {/* <p>
        We are hiring all positions!{" "}
        <Link to="/jobs" onClick={handleClose}>
          Learn More
        </Link>
      </p> */}
    </>
  );

  return (
    <Popup
      title={POP_UP_TITLE}
      message={POP_UP_MESSAGE}
      show={show}
      handleClose={handleClose}
    />
  );
}
