import React from "react";

export default function LoadingPage() {

  return (
    <section id="loading-page" className={`section-bg`}>
      <div className="loading-spinner" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </section>
  );
}
