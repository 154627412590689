// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDW1zUhQABU99bkd6spseBVRurAR3sg3aM",
  authDomain: "thelewistonlodge.firebaseapp.com",
  projectId: "thelewistonlodge",
  storageBucket: "thelewistonlodge.appspot.com",
  messagingSenderId: "782654400931",
  appId: "1:782654400931:web:e309fdcc76a835ae3dd216",
  measurementId: "G-M64Q7SE2NW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
