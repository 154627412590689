import React from "react";
import NavigationLinks from "../NavigationLinks";
// import NERD_NIBBLE_LOGO from "../../images/logo-lewistonlodge-primary.png";
import StoreHours from "../StoreHours";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top pt-0">
        <iframe
          title="Map view of the Lewiston Lodge in Lewiston, Michigan"
          id="embedded-map-loader"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10635.333141164832!2d-84.31385600000002!3d44.862941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1972ed7357614c3b!2sLewiston%20Lodge!5e1!3m2!1sen!2sus!4v1607019451289!5m2!1sen!2sus"
          frameBorder="0"
          aria-hidden="false"
          tab-index={0}
          style={{
            border: 0,
            width: "100%",
          }}
          allowFullScreen
        ></iframe>
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-info text-center">
                <h3> Lewiston Lodge</h3>
                <p>
                  1525 Fleming Rd
                  <br />
                  Lewiston MI, 49756
                  <br />
                  <br />
                  <strong>Restaurant:</strong> +1 989 786 5261
                  <br />
                  <strong>Lodge:</strong> +1 989 786 2452
                  <br />
                  <strong>Email:</strong> thelewistonlodge@gmail.com
                  <br />
                </p>
                <br />
                <p>
                  <StoreHours />
                </p>
              </div>
            </div>

            <div className="col-md-6 footer-links text-center">
              <NavigationLinks />
            </div>

            {/* <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="#">Web Design</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="#">Web Development</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="#">Product Management</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="#">Marketing</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="#">Graphic Design</a>
                </li>
              </ul>
            </div> */}

            {/* <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Our Newsletter</h4>
              <p>
                Tamen quem nulla quae legam multos aute sint culpa legam noster
                magna
              </p>
              <form action="" method="post">
                <input type="email" name="email" />
                <input type="submit" value="Subscribe" />
              </form>
            </div> */}
          </div>
        </div>
      </div>

      <div className="copyright">
        <strong>
          <span>Timothy's Lewiston Lodge, Inc.</span>
        </strong>
        <span> All Rights Reserved</span>
      </div>
    </footer>
  );
}
