import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import NavigationLinks from "../NavigationLinks";

export default function NavBar() {
  const location = useLocation();

  //I'm doing this because the nav needs to scroll to the top when a link is clicked
  useEffect(() => {
    toggleNav(false);
    window.scrollTo(0, 0);
  }, [location]);

  let toggleNav = (open: boolean) => {
    if (open) {
      //This class hides/shows the nav
      document.body.classList.add("mobile-nav-active");
    } else {
      document.body.classList.remove("mobile-nav-active");
    }
  };

  return (
    <>
      <div id="topbar" className="d-flex align-items-center fixed-top" style={{ zIndex: 1 }}>
        <div className="container d-flex">
          <div className="contact-info mr-auto">
            <a href="tel:+19897865261" className="ml-3">
              <i className="icofont-phone"></i>
              <span className="text-white"> +1 989 786 5261</span>
            </a>
            <a href="https://www.facebook.com/thelewistonlodge" className="ml-3">
              <i className="icofont-facebook"></i>
              <span className="text-white">/thelewistonlodge</span>
            </a>
          </div>
          {/* <div className="languages">
            <i className="text-primary icofont-clock-time"></i>
            <span> Mon-Thu: 11:00 AM - 11:00 PM  Fri-Sat: 11:00 AM - 12:00 PM</span>
          </div> */}
        </div>
      </div>

      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto">
            <Link to="/">Lewiston Lodge</Link>
          </h1>
          <nav className="nav-menu d-none d-lg-block">
            <NavigationLinks />
          </nav>
          <button
            type="button"
            className="mobile-nav-toggle d-lg-none"
            onClick={() => {
              toggleNav(true);
            }}
          >
            <div></div>
            <div></div>
            <div></div>
          </button>
        </div>
      </header>

      <div>
        <nav className="mobile-nav d-lg-none">
          <button
            type="button"
            className="mobile-nav-close"
            onClick={() => {
              toggleNav(false);
            }}
          >
            ×
          </button>
          <NavigationLinks />
        </nav>
        <div className="mobile-nav-overly"></div>
      </div>
    </>
  );
}
