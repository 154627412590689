import React, { lazy, Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import NavBar from "./components/navbar/NavBar";
import Footer from "./components/footer/Footer";
import GlobalPopup from "./components/GlobalPopup";
import LoadingPage from "./components/pages/LoadingPage";
import ErrorBoundary from "./components/pages/ErrorBoundary";
import Jobs from "./components/pages/jobs/Jobs";

const HomePage = lazy(() => import("./components/pages/home/HomePage"));
const AboutPage = lazy(() => import("./components/pages/about/AboutPage"));
const MenuPage = lazy(() => import("./components/pages/menu/MenuPage"));
const LodgingPage = lazy(() => import("./components/pages/lodging/LodgingPage"));
const NewsPage = lazy(() => import("./components/pages/news/NewsPage"));
const ContactPage = lazy(() => import("./components/pages/contact/ContactPage"));
// const BuyGiftCardsPage = lazy(() => import("./components/pages/giftcards/BuyGiftCardsPage"));
const SuccessPage = lazy(() => import("./components/pages/success/SuccessPage"));
const NotFoundPage = lazy(() => import("./components/pages/NotFoundPage"));

function App() {
  const location = useLocation();

  return (
    <>
      <GlobalPopup />
      <NavBar />
      <Suspense fallback={<LoadingPage />}>
        <ErrorBoundary key={location.pathname}>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/menu">
              <MenuPage />
            </Route>
            <Route path="/lodging">
              <LodgingPage />
            </Route>
            <Route path="/news">
              <NewsPage />
            </Route>
            <Route path="/jobs">
              <Jobs />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route exact path="/order">
              {/* <BuyGiftCardsPage /> */}

              <section id="contact" className="gift-card section-bg">
                <div className="container" data-aos="fade-up">
                  <div className="section-title">
                    <h2>Shop</h2>
                    <p>Gift Cards</p>
                  </div>
                  <h3>Temporarily Unavailable</h3>
                  <p>Gift card ordering is temporarily unavailable while we upgrade our systems.</p>
                </div>
              </section>
            </Route>
            <Route exact path="/order/success">
              <SuccessPage />
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </ErrorBoundary>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;
