import React from "react";
import docs from "./docs.svg";
import "./Jobs.scss"
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function Jobs() {
  return (
    <>
      <section id="about" className="about jobs section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Job Opportunities</h2>
            <p>We're Hiring!</p>
          </div>
          <div className="row">
            <div
              className="col-lg-6 order-1 order-lg-2 text-center text-md-right mb-5"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="jobs-img d-none d-md-block">
                <img src={docs} alt="" />
              </div>
            </div>
            <div className="col-lg-6 pt-lg-0 pb-5 align-self-center">
              <p>
                We are hiring in all of our positions! We value our employees and offer flexible positions,
                whether you are looking for a full time, part time, seasonal, or year round position we would love to hear from you!
              </p>
              <hr/>
              <p>
                We are hiring the following positions:
                <ul>
                  <li>Cook</li>
                  <li>Waitor / Waitress</li>
                  <li>Host / Hostess</li>
                  <li>Dishwasher</li>
                  <li>Housekeeper</li>
                </ul>
              </p>
              <Link to="/contact">
                <Button
                  size="lg"
                  variant="primary"
                  type="submit"
                >
                  Contact Us
              </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
