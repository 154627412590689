import React from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";

type Props = {
  title: string | JSX.Element,
  message: string | JSX.Element,
  show: boolean,
  handleClose: any,
};
export default function Popup({ title, message, show, handleClose }: Props) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer className="justify-content-center d-flex ">
          <Button
            className="w-50"
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
